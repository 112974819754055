//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    XModal: () => import("@/components/XModal"),
  },
  props: {
    teamVal: {
      type: Number,
      default: null,
    },
    dataClear: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      form: {
        Type: 4,
        TimeLoopType: 2,
        Time1: 1,
        Time2: 1,
        Storage: null,
        ClearType: null,
        ClearTotalDay: null,
        teamId: null,
        Id: null,
      },
    };
  },
  methods: {
    onConfirm() {
      this.form.teamId = this.$refs.initdata.loadBtn(true);
      this.form.teamId = this.teamVal;
      this.form.Id = this.dataClear.Id;
      this.$http
        .post("/Teams/DatabaseSetting/SaveDatabaseClearSetting.ashx", this.form)
        .then((resp) => {
          if (resp.res == 0) {
            this.$modal.hide("initdata");
            this.$emit("success");
          }
        })
        .finally(() => this.$refs.initdata.loadBtn(false));
    },
    opened() {
      this.$nextTick(() => {
        if (this.dataClear) {
          this.form.Type = this.dataClear.Type;
          this.form.TimeLoopType = this.dataClear.TimeLoopType;
          this.form.Time1 = this.dataClear.Time1;
          this.form.Time2 = this.dataClear.Time2;
          this.form.Storage = this.dataClear.Storage;
          this.form.ClearType = this.dataClear.ClearType;
          this.form.ClearTotalDay = this.dataClear.ClearTotalDay;
        } else {
          Object.assign(this.$data, this.$options.data());
        }
      });
    },
    /**
     * 清除类型切换
     */
    typeChange(val) {
      if (val == 2) {
        this.form.TimeLoopType = 2;
        this.form.Time1 = 1;
        this.form.Time2 = 1;
        this.form.Storage = null;
        this.form.ClearType = 2;
        this.form.ClearTotalDay = null;
      } else if (val == 3 || val == 4 || val == 1) {
        this.form.TimeLoopType = null;
        this.form.Time1 = null;
        this.form.Time2 = null;
        this.form.Storage = null;
        this.form.ClearType = val == 1 ? null : 2;
        this.form.ClearTotalDay = null;
      }
    },
    /**
     * 执行时间循环类型切换
     */
    timeLoopTypeChange(val) {
      if (val == 1) {
        this.form.Time1 = null;
        this.form.Time2 = 1;
      } else {
        this.form.Time1 = 1;
        this.form.Time2 = 1;
      }
    },
    /**
     * 执行清理模式切换
     */
    clearTypeChange(val) {
      this.form.ClearTotalDay = null;
    },
    getWeek(val) {
      switch (val) {
        case 1:
          return "一";
        case 2:
          return "二";
        case 3:
          return "三";
        case 4:
          return "四";
        case 5:
          return "五";
        case 6:
          return "六";
        case 7:
          return "日";
      }
    },
  },
};
